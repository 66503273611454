import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../layout/Svgs/CloseIcon";
import InfoIcon from "../../../layout/Svgs/InfoIcon";

export default function ScheduleItem({
  activity = "Lecture Name",
  timeFrom = "Time",
  timeTo = "Time",
  isOpen,
  onTooltipShow,
  mobile = false,
  startTimeHasMinutes
}) {
  return (
    <div
      className={`
      ${!isOpen ? 'bg-white' : 'bg-primary'}
      border-2  rounded-sm
      ${!mobile ? 'px-4' : 'p-12'}
      ${!mobile ? 'text-sm' : 'text-lg'}
      hover:border-primary
      ${isOpen ? 'border-primary' : 'border-scheduleBorder'}
      transition-colors duration-75
      cursor-pointer
      shadow-md
      h-full
      mt-2 ml-2 mr-2
      grid
      relative
    `}
    onClick={onTooltipShow}
    style={{
      height: (startTimeHasMinutes && !mobile) ? '45%' : '100%',
      marginTop: (startTimeHasMinutes && !mobile) ? '50px' : '0',
    }}
    >
      <div className={`absolute right-0 px-2 pt-1
      ${isOpen ? 'block' : 'hidden'}`}>
        <CloseIcon />
      </div>

      <div className={`absolute right-0 px-2 pt-1
      ${!isOpen ? 'block' : 'hidden'}`}>
        <InfoIcon />
      </div>
      <div className={`${!mobile ? 'self-center ' :'' }`}>
        <span className={`-mb-1 font-bold ${isOpen ? 'text-white' : 'text-black'} text-13px`}>{activity}</span>
        <div className={`text-xs ${!isOpen ? 'text-scheduleTimeText' : 'text-white'}`}>
          {timeFrom} - {timeTo}
        </div>
      </div>
    </div>
  );
}

ScheduleItem.defaultProps = {
  activity: "Lecture Name",
  timeFrom: "Time",
  timeTo: "Time",
};

ScheduleItem.propTypes = {
  activity: PropTypes.string,
  timeFrom: PropTypes.string,
  timeTo: PropTypes.string,
};
