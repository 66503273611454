import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Pill from '../../shared/Pill/Pill'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

const CourseViewOverviewDetailsItem = (props) => {
  const { body, header, title, newBody, hasBullets } = props

  const doesNewBodyExist = newBody !== undefined && newBody !== null;

  const extraContent = doesNewBodyExist && (
    <div className='mt-4'>
      <HtmlContent
        html={newBody.html}
        className='c-prose text-xs text-gray-darkest'
      />
    </div>
  );

  return (
    <div>
      <Pill className='mb-2'>
        {title}
      </Pill>
      <p className='h4 text-primary mb-1'>{header}</p>
      <div className={`min-h-62px ${hasBullets ? 'border-b border-gray-light' : ''}`}>
        <div className='text-sm space-y-1 pb-4'>{body}</div>
      </div>
      {extraContent}
    </div>
  )
}

CourseViewOverviewDetailsItem.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  newBody: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
    PropTypes.array
  ]),
  title: PropTypes.string.isRequired,
  hasBullets: PropTypes.bool,
}

CourseViewOverviewDetailsItem.defaultProps = {
  newBody: '',
  hasBullets: false,
}

export default CourseViewOverviewDetailsItem
