import React, { forwardRef, useEffect, useRef, useState } from "react";
import ScheduleHeading from "./ScheduleHeading";
import ScheduleItem from "./ScheduleItem";
import HtmlContent from "../../../shared/HtmlContent/HtmlContent";
import Image from "../../../shared/Image/Image";

function InfoSvg() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8 0C5.28005 0 0.800049 4.48 0.800049 10C0.800049 15.52 5.28005 20 10.8 20C16.32 20 20.8 15.52 20.8 10C20.8 4.48 16.32 0 10.8 0ZM9.80005 5V7H11.8V5H9.80005ZM9.80005 9V15H11.8V9H9.80005ZM2.80005 10C2.80005 14.41 6.39005 18 10.8 18C15.21 18 18.8 14.41 18.8 10C18.8 5.59 15.21 2 10.8 2C6.39005 2 2.80005 5.59 2.80005 10Z"
        fill="#8A8A8A"
      />
    </svg>
  );
}

export default function CourseViewPartTimeSchedule({
  exampleToggled,
  setExampleToggled,
  partTimeTestominals,
}) {
  const [firstSchedule] = useState([
    {
      day: "Monday",
      startTime: "19:00",
      endTime: "20:00",
      activity: "Lecture 1",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Lecture",
        description:
          "Lasting one hour, these are held twice a week and watching these is mandatory. However, we do record these sessions, enabling you to catch up at a time that suits you that week.",
      },
    },
    {
      day: "Tuesday",
      startTime: "19:00",
      endTime: "20:00",
      activity: "Seminar",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Seminar",
        description:
          "Seminars are live and one hour in duration, these occur twice weekly. These sessions are not recorded, so you will need to attend at least one live seminar.",
      },
    },
    {
      day: "Wednesday",
      startTime: "18:00",
      endTime: "19:00",
      activity: "Lecture 2",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Lecture",
        description:
          "Lasting one hour, these are held twice a week and watching these is mandatory. However, we do record these sessions, enabling you to catch up at a time that suits you that week.",
      },
    },
    {
      day: "Wednesday",
      startTime: "19:00",
      endTime: "21:00",
      activity: "Study Group",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Study Group",
        description:
          "Study Groups offer a chance to pose questions, share insights, and provide feedback on coding issues among peers. While attendance isn't compulsory, engaging with the challenges and solutions of others is a valuable learning tool for enhancing problem-solving skills.",
      },
    },
    {
      day: "Thursday",
      startTime: "20:30",
      endTime: "21:00",
      activity: "30 min 1-2-1",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "1-2-1",
        description:
          "One-to-one sessions with a mentor are available on an optional basis, allowing you to schedule a 30-minute slot to discuss your course progression, address any coding challenges, or seek general assistance.",
      },
    },
    // Add more items as needed
  ]);
  const [secondSchedule] = useState([
    {
      day: "Monday",
      startTime: "13:00",
      endTime: "14:00",
      activity: "Lecture 1",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Monday Lecture",
        description:
          "Lasting one hour, these are held twice a week and watching these is mandatory. However, we do record these sessions, enabling you to catch up at a time that suits you that week.",
      },
    },
    {
      day: "Tuesday",
      startTime: "13:00",
      endTime: "13:30",
      activity: "30 min 1-2-1",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "1-2-1",
        description:
          "One-to-one sessions with a mentor are available on an optional basis, allowing you to schedule a 30-minute slot to discuss your course progression, address any coding challenges, or seek general assistance.",
      },
    },
    {
      day: "Wednesday",
      startTime: "13:00",
      endTime: "14:00",
      activity: "Lecture 2",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Lecture",
        description:
          "Lasting one hour, these are held twice a week and watching these is mandatory. However, we do record these sessions, enabling you to catch up at a time that suits you that week.",
      },
    },
    {
      day: "Thursday",
      startTime: "13:00",
      endTime: "14:00",
      activity: "Seminar",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Seminar",
        description:
          "Seminars are live and one hour in duration, these occur twice weekly. These sessions are not recorded, so you will need to attend at least one live seminar.",
      },
    },
    {
      day: "Friday",
      startTime: "13:00",
      endTime: "15:00",
      activity: "Study Group",
      type: "",
      ref: useRef(null),
      isOpen: false,
      tooltip: {
        title: "Study Group",
        description:
          "Study Groups offer a chance to pose questions, share insights, and provide feedback on coding issues among peers. While attendance isn't compulsory, engaging with the challenges and solutions of others is a valuable learning tool for enhancing problem-solving skills.",
      },
    },
  ]);
  const [schedule, setSchedule] = useState(firstSchedule);

  function setScheduleBasedOnExample() {
    if (exampleToggled) {
      setSchedule(secondSchedule);
    } else {
      setSchedule(firstSchedule);
    }
  }

  useEffect(() => {
    setScheduleBasedOnExample();
  }, [exampleToggled, setSchedule, firstSchedule, secondSchedule]);

  // Creating the left-side hours:
  // By default we begin at 5pm, as the schedule ends at 9pm.
  // If the second example is toggled, then we begin at 11am.
  const hours = Array.from(
    { length: 6 },
    (_, i) => (!exampleToggled ? 17 : 11) + i
  );

  const scheduleDayHeaders = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];
  const timeToGridPosition = (startTime, endTime) => {
    const scheduleStartHour = !exampleToggled ? 17 : 11; // Schedule starts at 5 PM
    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinute = parseInt(startTime.split(":")[1], 10);
    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinute = parseInt(endTime.split(":")[1], 10);

    const rowStart = startHour - scheduleStartHour + 2; // +2 to account for header rows
    let rowSpan = endHour - startHour;

    if (startMinute > 0) {
      rowSpan += 1;
    }

    return { rowStart, rowSpan };
  };

  return (
    <section>
      <ul
        className="
                            mt-12 mb-6
                            grid
                            grid-cols-1 md:grid-cols-2
                            gap-4
                          "
      >
        {partTimeTestominals.map((testimonial, index) => {
          return (
            <>
              <li
                className={`
                                        cursor-pointer
                                  bg-scheduleGrey
                                  shadow-sm
                                  relative
                                  border-t-2 border-transparent
                                  ${
                                    index === 0 && !exampleToggled
                                      ? "border-t-2 border-primary"
                                      : ""
                                  }
                                  ${
                                    index === 1 && exampleToggled
                                      ? "border-t-2 border-primary"
                                      : ""
                                  }
                                `}
                onClick={() => {
                  if (index === 1 && !exampleToggled) setExampleToggled(true);
                  if (index === 0 && exampleToggled) setExampleToggled(false);
                }}
              >
                <div className="w-24 h-24 rounded-full absolute -top-17px -left-13px">
                  <Image
                    className="w-full h-full rounded-full"
                    {...testimonial.testimonial_image}
                    fallbackAlt={testimonial.testominal_name.text}
                  />
                </div>

                <h3 className="text-center pt-6">
                  {testimonial.testominal_name.text}
                </h3>
                <div className="text-center my-2">
                  <p className="bg-ncYellow text-xs px-4 py-2 inline rounded-md">
                    {testimonial.testominal_tag.text}
                  </p>
                </div>

                <HtmlContent
                  html={testimonial.testimonial_description.html}
                  className="c-prose py-4 px-6"
                />

                <div className="px-6 pb-4">
                  {index === 0 ? (
                    <button
                      className={`mr-6 ${
                        !exampleToggled
                          ? "shadow-sm bg-primary text-white px-4 py-2 rounded-md"
                          : "border border-primary text-primary px-4 py-2 rounded-md font-medium"
                      }`}
                      onClick={() => setExampleToggled(false)}
                    >
                      Example 1
                    </button>
                  ) : (
                    <button
                      className={`${
                        exampleToggled
                          ? "shadow-sm bg-primary text-white px-4 py-2 rounded-md"
                          : "border border-primary text-primary px-4 py-2 rounded-md font-medium"
                      }`}
                      onClick={() => setExampleToggled(true)}
                    >
                      Example 2
                    </button>
                  )}
                </div>
              </li>
            </>
          );
        })}
      </ul>

      <div className="block lg:hidden">
        {scheduleDayHeaders.map((day, dayIndex) => {
          const scheduleByDay = schedule.filter(
            (item) => item.day.toLowerCase().trim() === day.toLowerCase().trim()
          );
          if (scheduleByDay.length === 0) return null;

          return (
            <>
              <div className={`text-xl text-darkerText font-medium
              ${dayIndex === 0 ? 'pb-4' : 'py-4'}`}>{day}</div>

              {scheduleByDay.map((item, index) => {
                const mobileScheduleTooltip = useRef(null);
                const onTooltipShow = () => {
                  if (
                    mobileScheduleTooltip.current.classList.contains("hidden")
                  ) {
                    mobileScheduleTooltip.current.classList.remove("hidden");
                    setSchedule((prevSchedule) => {
                      return prevSchedule.map((prevItem, prevIndex) => {
                        if (prevIndex === index) {
                          return {
                            ...prevItem,
                            isOpen: true,
                          };
                        } else {
                          return prevItem;
                        }
                      });
                    });
                  } else {
                    mobileScheduleTooltip.current.classList.add("hidden");
                    setSchedule((prevSchedule) => {
                      return prevSchedule.map((prevItem, prevIndex) => {
                        if (prevIndex === index) {
                          return {
                            ...prevItem,
                            isOpen: false,
                          };
                        } else {
                          return prevItem;
                        }
                      });
                    });
                  }
                };

                const isOpen = mobileScheduleTooltip.current
                  ? !mobileScheduleTooltip.current.classList.contains("hidden")
                  : false;

                return (
                  <>
                    <ScheduleItem
                      activity={item.activity}
                      timeFrom={item.startTime}
                      timeTo={item.endTime}
                      isOpen={isOpen}
                      onTooltipShow={onTooltipShow}
                      mobile={true}
                      startTimeHasMinutes={
                        item.startTime.split(":")[1] !== "00"
                      }
                    />

                    <div
                      className="bg-primary my-4 p-6 text-white hidden"
                      ref={mobileScheduleTooltip}
                    >
                      <h1 className="py-4 text-2xl font-bold">
                        {item.tooltip.title}
                      </h1>
                      <p>{item.tooltip.description}</p>
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>

      <div
        className="hidden lg:grid grid-cols-6"
        style={{ gridTemplateRows: "repeat(6, 1fr)" }}
      >
        <div className="bg-gradient-to-t from-scheduleGrey to-white"></div>
        <ScheduleHeading headerText="Monday" />
        <ScheduleHeading
          headerText="Tuesday"
          color="bg-gradient-to-t from-scheduleGrey to-white"
        />
        <ScheduleHeading headerText="Wednesday" />
        <ScheduleHeading
          headerText="Thursday"
          color="bg-gradient-to-t from-scheduleGrey to-white"
        />
        <ScheduleHeading headerText="Friday" />
        {hours.map((hour, index) => {
          const lastItem = index === hours.length - 1;
          return (
            <React.Fragment key={`hour-${hour}`}>
              <div
                className={`text-center p-2 font-medium text-darkerText ${
                  lastItem
                    ? "bg-gradient-to-t from-white to-scheduleGrey"
                    : "bg-scheduleGrey"
                }`}
                style={{
                  gridRowStart: index + 2,
                  gridColumnStart: 1,
                }}
              >
                {`${hour}:00`}
              </div>
            </React.Fragment>
          );
        })}
        {schedule.map((item, index) => {
          const { rowStart, rowSpan } = timeToGridPosition(
            item.startTime,
            item.endTime
          );

          const startTimeHasMinutes = item.startTime.split(":")[1] !== "00";
          const endTimeHasMinutes = item.endTime.split(":")[1] !== "00";

          let colStart;
          switch (item.day) {
            case "Monday":
              colStart = 2;
              break;
            case "Tuesday":
              colStart = 3;
              break;
            case "Wednesday":
              colStart = 4;
              break;
            case "Thursday":
              colStart = 5;
              break;
            case "Friday":
              colStart = 6;
              break;
            default:
              colStart = 2;
          }
          const onTooltipShow = () => {
            // Firstly, loop through the schedule and find any
            // other open tooltips and close them.
            schedule.forEach((scheduleItem, sIndex) => {
              if (scheduleItem.ref.current !== item.ref.current) {
                if (
                  scheduleItem.ref.current &&
                  !scheduleItem.ref.current.classList.contains("hidden")
                ) {
                  setSchedule((prevSchedule) => {
                    return prevSchedule.map((prevItem) => {
                      if (
                        prevItem.ref.current &&
                        !prevItem.ref.current.classList.contains("hidden")
                      ) {
                        prevItem.isOpen = false;
                        prevItem.ref.current.classList.add("hidden");
                      }
                      return prevItem;
                    });
                  });
                }
              }
            });
            if (item.ref.current) {
              // Check if the class 'hidden' exists, if it does
              // then we remove it and show the tooltip.
              // If it does not, then we add the class 'hidden'
              if (item.ref.current.classList.contains("hidden")) {
                setSchedule((prevSchedule) => {
                  return prevSchedule.map((prevItem, prevIndex) => {
                    if (prevIndex === index) {
                      item.ref.current.classList.remove("hidden");
                      return {
                        ...prevItem,
                        isOpen: true,
                      };
                    } else {
                      return prevItem;
                    }
                  });
                });
              } else {
                setSchedule((prevSchedule) => {
                  return prevSchedule.map((prevItem, prevIndex) => {
                    if (prevIndex === index) {
                      item.ref.current.classList.add("hidden");
                      return {
                        ...prevItem,
                        isOpen: false,
                      };
                    } else {
                      return prevItem;
                    }
                  });
                });
              }
            }
          };
          const scheduleItemProps = {
            ...item,
            timeFrom: item.startTime,
            timeTo: item.endTime,
            startTimeHasMinutes,
            endTimeHasMinutes,
            onTooltipShow,
          };

          return (
            <div
              key={index}
              className={`
                bg-transparent
                border-l
                border-scheduleBorder
                p-2
                relative
              `}
              style={{
                gridColumnStart: colStart,
                gridRowStart: rowStart,
                gridRowEnd: `span ${
                  startTimeHasMinutes ? rowSpan + 1 : rowSpan
                }`,
              }}
            >
              <ScheduleItem {...scheduleItemProps} />
              <div
                className="absolute bg-white border-2 border-scheduleBorder w-64 hidden p-4 z-aboveAll2"
                ref={item.ref}
              >
                <InfoSvg />
                <h1 className="text-2xl font-bold py-4">
                  {item.tooltip.title}
                </h1>
                <p>{item.tooltip.description}</p>
              </div>
            </div>
          );
        })}

        {/* If not every day is filled in until 17:00, then there will be blank */}
        {/* areas within the schedule, we want to fill these in with a white block. */}
        {hours.map((hour, index) => {
          const lastItem = index === hours.length - 1;
          return (
            <React.Fragment key={`hour-${hour}-filler`}>
              <div
                className="bg-white border-l border-scheduleBorder"
                style={{
                  gridColumnStart: 2,
                  gridRowStart: index + 2,
                }}
              ></div>
              <div
                className={`border-l border-scheduleBorder ${
                  lastItem
                    ? "bg-gradient-to-b from-scheduleGrey to-white"
                    : "bg-scheduleGrey"
                }`}
                style={{
                  gridColumnStart: 3,
                  gridRowStart: index + 2,
                }}
              ></div>
              <div
                className="bg-white border-l border-scheduleBorder"
                style={{
                  gridColumnStart: 4,
                  gridRowStart: index + 2,
                }}
              ></div>
              <div
                className={`border-l border-scheduleBorder ${
                  lastItem
                    ? "bg-gradient-to-b from-scheduleGrey to-white"
                    : "bg-scheduleGrey"
                }`}
                style={{
                  gridColumnStart: 5,
                  gridRowStart: index + 2,
                }}
              ></div>
              <div
                className="bg-white border-l border-scheduleBorder"
                style={{
                  gridColumnStart: 6,
                  gridRowStart: index + 2,
                }}
              ></div>
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
}
