import React from "react";

export default function InfoIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM8.25001 3.75H6.75001V5.25H8.25001V3.75ZM8.25001 6.74998H6.75001V11.25H8.25001V6.74998ZM1.5 7.49999C1.5 10.8075 4.1925 13.5 7.5 13.5C10.8075 13.5 13.5 10.8075 13.5 7.49999C13.5 4.19249 10.8075 1.49999 7.5 1.49999C4.1925 1.49999 1.5 4.19249 1.5 7.49999Z"
        fill="#8A8A8A"
      />
    </svg>
  );
}
