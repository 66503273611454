import React from 'react'

// Components
import Button from '../../../shared/Button/Button'

// Assets
import SkillsForLife from "../../../../assets/png/skills-for-life-bootcamps-white.png";
import LogoSkillsEligibility from '../../../../assets/img/logo-skills-eligibility.svg'

export default function SkillsEligibilityCheckerBanner({
    openModal
}) {
  return (
    <div
        className="
            bg-primary text-white
            relative
            flex flex-col
            overflow-hidden
            shadow-2xl
        "
    >
        <div className='
            p-12
            text-center sm:text-left
            z-skillsEligibilityCheckerBannerLogoParent

            w-full md:w-3/4
        '>
            <h1
                className='
                    text-4xl
                    font-bold
                '
            >Skills Eligibility Checker</h1>
            <p
                className='
                    text-lg
                    pt-2
                    w-full md:w-2/3
                '
            >
                In just one minute you can check your eligibility for the <strong>Skills for Life</strong> bootcamp. <br />
                <span className='
                    block
                    pt-2
                '>If you’re not eligible we do have self funded options available.</span>
            </p>
            <Button
                color='secondary'
                className='
                    mt-8
                    bg-black hover:bg-primary-hover
                    text-white hover:text-white
                    border-transparent hover:border-black
                    cursor-pointer
                    shadow-xl
                '
                onClick={openModal}
                icon
            >
                Check now
            </Button>
            <img
                src={SkillsForLife}
                className='
                    w-145px
                    mt-16
                    mx-auto sm:mx-0
                    bg-
                '
            />
        </div>

        <LogoSkillsEligibility className='
            absolute
            -top-20
            xs:w-auto sm:w-full xl:w-3/4
            xs:right-0 sm:-right-20 md:-right-20rem xl:-right-20
            z-skillsEligibilityCheckerBannerLogo
            brightness-40 lg:brightness-100
        ' />
    </div>
  )
}
