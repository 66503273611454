import React from 'react'
import PropTypes from 'prop-types'
import jump from 'jump.js'

// Utils
import toSlug from '../../../utils/toSlug'

// Generic
import Button from '../../shared/Button/Button'
import StickyLayoutNavLink from '../../shared/StickyLayout/StickyLayoutNavLink'

const CourseViewNav = (props) => {
  const { currentNav, navItems, setCanUpdateNav, setCurrentNav, data, isLimitedCoursePage } = props
  let registerInterest = false;
  if (isLimitedCoursePage) {
    registerInterest = true;
  }

  /**
   * Update the nav state in the parent component
   * @param  {Object} event
   * @param  {String} newNav
   * @return {Void}
   */
  const handleClick = (event, newNav) => {
    event.preventDefault()

    // Stop the nav from being update on the scroll event
    setCanUpdateNav(false)

    // Update the nav
    setCurrentNav(newNav)

    jump(event.currentTarget.hash, {
      callback: () => {
        // enable updating on the scroll event
        setCanUpdateNav(true)
      }
    })
  }

  let ctaLink = data.apply_cta_link;
  if (isLimitedCoursePage) {
    ctaLink = {
      ...ctaLink,
      url: '#register-interest'
    };
  }

  return (
    <>
      <ul>
        {navItems.map((item, itemIndex) => {
          return (
            <li
              key={itemIndex}
            >
              <StickyLayoutNavLink
                href={`#${toSlug(item)}`}
                isCurrent={toSlug(item) === currentNav}
                onClick={(event) => handleClick(event, toSlug(item))}
              >
                {item}
              </StickyLayoutNavLink>
            </li>
          )
        })}
      </ul>
      <div className='pt-4 px-6 pb-5'>
        <Button
          color='primary'
          className='flex'
          icon
          {...ctaLink}
        >
          {registerInterest ? 'Interested?' : 'Apply Now'}
        </Button>
      </div>
    </>
  )
}

CourseViewNav.propTypes = {
  currentNav: PropTypes.string.isRequired,
  navItems: PropTypes.array.isRequired,
  setCanUpdateNav: PropTypes.func.isRequired,
  setCurrentNav: PropTypes.func.isRequired
}

export default CourseViewNav
