import React from 'react'
import PropTypes from "prop-types";

export default function ScheduleHeading({
    headerText = 'Monday',
    color = 'bg-white',
    border = 'border-0',
}) {
  return (
    <div className={`${color} text-center px-6 font-medium text-darkerText ${border}`}>
        <div className="w-full h-full grid items-center">{headerText}</div>
    </div>
  )
}

ScheduleHeading.defaultProps = {
    headerText: 'Monday',
    color: 'bg-white',
    border: 'border-0',
}

ScheduleHeading.propTypes = {
    headerText: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.string,
}
