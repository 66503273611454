import React from "react";
import PropTypes from "prop-types";

import CourseComparisonMobileHeading from './CourseComparisonMobileHeading'
import CourseComparisonHeading from "./CourseComparisonHeading";
import CourseComparisonContentTable from "./CourseComparisonContentTable";
import CourseComparisonPrice from "./CourseComparisonPrice";

const CourseComparisonTable = ({
  data: {
    compare_against,
    compare_against_price,
    compare_against_extra_info,
    compare_to,
    compare_to_price,
    compare_to_extra_info,
    comparison_table_content,
  },
}) => {
  return (
    <section className="pb-4" id="course-comparison-table">
      <CourseComparisonMobileHeading />
      <CourseComparisonHeading
        compareAgainst={compare_against !== undefined ? compare_against.text : ""}
        compareTo={compare_to !== undefined ? compare_to.text : ""}
      />
      <CourseComparisonContentTable
        tableContent={comparison_table_content}
      />
      <CourseComparisonPrice
        compareAgainstPrice={compare_against_price !== undefined ? compare_against_price.text : ""}
        compareAgainstExtraInfo={compare_against_extra_info !== undefined ? compare_against_extra_info.text : ""}
        compareToPrice={compare_to_price !== undefined ? compare_to_price.text : ""}
        compareToExtraInfo={compare_to_extra_info !== undefined ? compare_to_extra_info.text : ""}
      />
    </section>
  );
};

CourseComparisonTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CourseComparisonTable;
