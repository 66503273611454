import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import jump from "jump.js";

// Content Components
import Faqs from "../../elements/Faqs/Faqs";
import Hero from "../../elements/Hero/Hero";
import TestimonialCarousel from "../../elements/TestimonialCarousel/TestimonialCarousel";
import HubspotForm from "../../elements/HubspotForm/HubspotForm";

// Generic
import Button from "../../shared/Button/Button";
import Container from "../../shared/Container/Container";
import CurriculumTabs from "../../shared/CurriculumTabs/CurriculumTabs";
import Edge from "../../shared/Edge/Edge";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";
import Image from "../../shared/Image/Image";
import Modal from "../../shared/Modal/Modal";
import Pill from "../../shared/Pill/Pill";
import StickyLayout from "../../shared/StickyLayout/StickyLayout";
import TwoColGrid from "../../shared/TwoColGrid/TwoColGrid";
import TwoColGridItem from "../../shared/TwoColGrid/TwoColGridItem";
import Embed from "../../shared/Embed/Embed";

// Utils
import toSlug from "../../../utils/toSlug";

// Local components
import CourseViewNav from "./CourseViewNav";
import CourseViewSection from "./CourseViewSection";
import CourseViewOverviewDetails from "./CourseViewOverviewDetails";
import CourseComparisonTable from "../../elements/CourseComparisonTable/CourseComparisonTable";

import SkillsEligibilityCheckerBanner from "../../elements/_custom/SkillsEligibilityChecker/SkillsEligibilityCheckerBanner";
import "./CourseViewMobileHideHubspotChat.css";
import CourseViewPartTimeSchedule from "./CourseViewPartTimeSchedule/CourseViewPartTimeSchedule";

const CourseView = ({
  data,
  openSkillsEligibilityModal,
  doesShowEligibility,
  currentCourse,
  isLimitedCoursePage,
  isPartTime
}) => {
  const partTimeTestominals = data.part_time_testimonials || [];
  const [showApplyNowButton, setShowApplyNowButton] = useState(false);
  const [exampleToggled, setExampleToggled] = useState(false);

  let navItems = ["Overview", "Curriculum", "Course Dates", "Finance", "FAQs"];
  if (isLimitedCoursePage) {
    navItems = ["Overview", "Finance", "FAQs"];
  }
  if (isPartTime) {
    navItems.splice(1, 0, "Schedule");
  }

  const modalElement = useRef(null);
  const skillsEligibilityModalElement = useRef(null);

  const [currentNav, setCurrentNav] = useState(toSlug(navItems[0]));
  const [canUpdateNav, setCanUpdateNav] = useState(true);

  // Sometimes we may have a Course Comparison table, let's check
  // if we have filled that in for this page.
  const {
    comparison_table_heading,
    comparison_table_body,
    compare_against,
    compare_against_price,
    compare_against_extra_info,
    compare_to,
    compare_to_price,
    compare_to_extra_info,
    comparison_table_content,
  } = data;

  let showComparisonTable = false;
  if (comparison_table_content !== undefined) {
    if (comparison_table_content.length > 0) {
      showComparisonTable = true;
    }
  }

  const imageSectionData = {
    title: data.image_section_title,
    bodyAbove: data.image_section_body_above,
    image: data.image_section_image,
    bodyBelow: data.image_section_body_below,
  };
  // Create a variable named 'showImageSection' and it can only be true
  // if there is an image, title, and bodyAbove OR bodyBelow.
  const showImageSection =
    imageSectionData.image &&
    imageSectionData.image.url &&
    imageSectionData.title.text &&
    (imageSectionData.bodyAbove.html || imageSectionData.bodyBelow.html);

  /**
   * Calculate the current scroll position and update the state if it's changed
   * @param  {Object} element
   * @param  {string} id
   * @return {Void}
   */
  const updateCurrentNavBasedOnScroll = (element, id) => {
    if (element.current) {
      // Why minus 1? Honestly I don't know. When clicking a nav link in the
      // `CourseViewNav` it was landing 1px too high, so this fixes it
      const elementTop =
        Math.round(element.current.getBoundingClientRect().top) - 1;
      const elementBottom =
        Math.round(elementTop + element.current.offsetHeight) - 1;

      // If the top of the viewport is within current element, set it as the
      // current nav item
      // Don't update if we're animating via a smooth scroll
      if (elementTop <= 0 && elementBottom >= 0 && canUpdateNav) {
        setCurrentNav(id);
      }
    }
  };

  /**
   * Open the modal in the child component
   * @param  {Object} event
   * @return {Void}
   */
  const handleOpenModal = (event) => {
    event.preventDefault();
    if (!isLimitedCoursePage) {
      modalElement.current.showModal();
    }
  };

  /**
   * Open the skills eligibility modal in the child component
   * @param  {Object} event
   * @return {Void}
   */
  const handleOpenSkillsEligibilityModal = (event) => {
    event.preventDefault();

    skillsEligibilityModalElement.current.showModal();
  };

  const scrollTo = (elementId) => {
    // Using jump.js, I want to target an element ID and scroll to it.
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      jump(element);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPercentage =
        (scrollPosition / (scrollHeight - windowHeight)) * 100;
        if (!isLimitedCoursePage) {
          if (modalElement.current) {
            if (modalElement.current.isVisible === false) {
              if (scrollPercentage >= 5) {
                setShowApplyNowButton(true);
              } else {
                setShowApplyNowButton(false);
              }
            }
          }
        }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="z-aboveAll2 static">
        <Hero
          data={{
            primary: {
              hero_title: data.title,
              hero_intro: data.course_intro,
              hero_illustration: data.course_illustration,
              logo_group: data.logo_group,
            },
          }}
          isCourse={true}
          additionalContent={
            <div className="mt-8 sm:mt-12 flex flex-wrap z-aboveAll2">
              <Button
                color="primary"
                className="mb-4 mr-4"
                icon
                isBroken={false}
                linkType="Document"
                target={null}
                url={
                  !isLimitedCoursePage ? "/apply#form" : "#register-interest"
                }
              >
                {isLimitedCoursePage ? "Register Interest" : "Apply Now"}
              </Button>
              {data.curriculum_form_guid && !isLimitedCoursePage ? (
                <Button
                  color="secondary"
                  className="mb-4 mr-4"
                  element="button"
                  onClick={handleOpenModal}
                  icon
                >
                  Download Curriculum
                </Button>
              ) : null}
            </div>
          }
        />
      </div>
      <StickyLayout
        breakpoint="lg"
        nav={
          <CourseViewNav
            navItems={navItems}
            currentNav={currentNav}
            setCurrentNav={setCurrentNav}
            setCanUpdateNav={setCanUpdateNav}
            data={data}
            isLimitedCoursePage={isLimitedCoursePage}
          />
        }
      >
        {({ leftSpacingClassName }) => {
          return (
            <>
              <CourseViewSection
                id={toSlug("Overview")}
                className="pb-16 md:pb-32"
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className="mb-6">{data.course_header.text}</h2>
                    <HtmlContent
                      html={data.course_body.html}
                      className="text-lg mb-12 c-prose"
                    />

                    {doesShowEligibility && !isLimitedCoursePage ? (
                      <div className="mb-12">
                        <SkillsEligibilityCheckerBanner
                          openModal={openSkillsEligibilityModal}
                        />
                      </div>
                    ) : null}
                    {!isLimitedCoursePage ? (
                      <CourseViewOverviewDetails data={data} />
                    ) : null}

                    {showComparisonTable ? (
                      <Container>
                        <h2 className="mb-6">
                          {comparison_table_heading.text}
                        </h2>
                        <HtmlContent
                          html={comparison_table_body.html}
                          className="text-lg mb-6 c-prose"
                        />

                        <CourseComparisonTable
                          data={{
                            compare_against,
                            compare_against_price,
                            compare_against_extra_info,
                            compare_to,
                            compare_to_price,
                            compare_to_extra_info,
                            comparison_table_content,
                          }}
                        />
                      </Container>
                    ) : null}

                    {(data?.course_video_embed?.html ||
                      data?.course_image_embed?.url) && (
                      <div className="mb-16">
                        {data?.course_video_embed?.html ? (
                          <Embed
                            embed={data.course_video_embed}
                            thumbnail={
                              data.course_image_embed.url
                                ? data.course_image_embed
                                : null
                            }
                            title={
                              data.course_video_embed_custom_title ||
                              data.course_video_embed.title
                            }
                          />
                        ) : (
                          <Image
                            fallbackAlt={data.course_header.text}
                            {...data.course_image_embed}
                          />
                        )}
                      </div>
                    )}
                    <TwoColGrid>
                      {data.course_features.map((item, itemIndex) => {
                        return (
                          <TwoColGridItem
                            key={itemIndex}
                            icon={item.course_features_icon}
                            header={item.course_features_header}
                            body={item.course_features_body.html}
                          />
                        );
                      })}
                    </TwoColGrid>
                  </div>
                </Container>
              </CourseViewSection>

              <CourseViewSection
                id={toSlug("Schedule")}
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    {showImageSection && (
                      <div>
                        <h2>{imageSectionData.title.text}</h2>
                        <HtmlContent
                          html={imageSectionData.bodyAbove.html}
                          className={"c-prose pt-4 pb-6"}
                        />

                        {isPartTime ? (
                            <CourseViewPartTimeSchedule
                              exampleToggled={exampleToggled}
                              setExampleToggled={setExampleToggled}
                              partTimeTestominals={partTimeTestominals}
                            />
                        ) : (
                          <Image
                            fallbackAlt={imageSectionData.bodyAbove.text}
                            {...imageSectionData.image}
                          />
                        )}
                        <HtmlContent
                          html={imageSectionData.bodyBelow.html}
                          className={"c-prose pt-6"}
                        />
                      </div>
                    )}
                  </div>
                </Container>
              </CourseViewSection>
              <CourseViewSection
                id={toSlug("Curriculum")}
                className={`bg-offwhite ${
                  isLimitedCoursePage ? "hidden" : "block"
                }`}
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Edge
                  location="top"
                  direction="right"
                  outerClassName="text-white"
                  innerClassName="text-offwhite"
                />
                <Container>
                  <div
                    className={classNames(
                      "pt-12 pb-16 sm:pb-32",
                      leftSpacingClassName
                    )}
                  >
                    <div className="max-w-2xl">
                      <h2 className="mb-6 sm:mb-4">
                        {data.course_curriculum_title.text}
                      </h2>
                      <CurriculumTabs
                        tabs={data.course_curriculum_tabs.map((tab) => {
                          return {
                            value: tab.course_curriculum_tab_value.text,
                            header: tab.course_curriculum_tab_header.text,
                            body: tab.course_curriculum_tab_body.html,
                            footnote: tab.course_curriculum_tab_footnote.html,
                          };
                        })}
                      />
                    </div>
                  </div>
                </Container>
                {data.course_curriculum_additional_info_grid.length > 0 && (
                  <div className="relative -mt-12 sm:-mt-20 mb-4">
                    <Container>
                      <div
                        className={classNames(
                          "relative z-10",
                          leftSpacingClassName
                        )}
                      >
                        <div className="bg-white shadow-lg p-4 sm:p-6 md:p-16 xl:-ml-16">
                          <TwoColGrid>
                            {data.course_curriculum_additional_info_grid.map(
                              (item, itemIndex) => {
                                return (
                                  <TwoColGridItem
                                    key={itemIndex}
                                    icon={
                                      item.course_curriculum_additional_info_grid_icon
                                    }
                                    header={
                                      item.course_curriculum_additional_info_grid_header
                                    }
                                    body={
                                      item
                                        .course_curriculum_additional_info_grid_body
                                        .html
                                    }
                                  />
                                );
                              }
                            )}
                          </TwoColGrid>
                        </div>
                      </div>
                    </Container>
                    <div className="absolute bottom-0 left-0 w-full h-1/3 bg-white" />
                  </div>
                )}
              </CourseViewSection>
              <CourseViewSection
                id={toSlug("Course Dates")}
                className={`mb-12 pt-12 sm:pt-16 md:pt-24 ${
                  isLimitedCoursePage ? "hidden" : "block"
                }`}
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className="mb-6">{data.course_dates_title.text}</h2>
                    <div className="space-y-6">
                      {data.course_dates.map((item, itemIndex) => {
                        let buttonElementLinkProps = { ...data.apply_cta_link };
                        const sendToSelfFundedPage =
                          item.send_to_self_funded_page !== null &&
                          item.send_to_self_funded_page !== false &&
                          item.send_to_self_funded_page !== undefined;
                        if (sendToSelfFundedPage) {
                          let url = "";

                          switch (currentCourse) {
                            case "software":
                              url =
                                "https://northcoders.com/our-courses/coding-bootcamp";
                              break;
                            case "data":
                              url =
                                "https://northcoders.com/our-courses/data-engineering-bootcamp";
                              break;
                            default:
                              url =
                                "https://northcoders.com/our-courses/cloud-engineering-bootcamp";
                              break;
                          }

                          buttonElementLinkProps = {
                            isBroken: false,
                            linkType: "Document",
                            target: null,
                            url,
                          };
                        }
                        return (
                          <div
                            key={itemIndex}
                            className="shadow-lg sm:flex items-center justify-between sm:space-x-4 px-4 sm:px-10 py-6 sm:py-12 text-center sm:text-left"
                          >
                            <div className="mb-6 sm:mb-0">
                              {item.course_dates_extra_label.text && (
                                <Pill className="mb-2">
                                  {item.course_dates_extra_label.text}
                                </Pill>
                              )}
                              <h4 className="text-primary mb-1">
                                {item.course_dates_date}
                              </h4>
                              <p>{item.course_dates_location.text}</p>
                            </div>

                            {item.course_dates_display_apply_now_button ? (
                              <Button
                                color="primary"
                                className="w-full sm:w-auto"
                                icon
                                {...buttonElementLinkProps}
                              >
                                {!sendToSelfFundedPage
                                  ? isLimitedCoursePage
                                    ? "Register Interest"
                                    : "Apply Now"
                                  : "Find Out More"}
                              </Button>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Container>
              </CourseViewSection>
              <CourseViewSection
                id={toSlug("Finance")}
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div
                    className={classNames("pt-12 mb-16", leftSpacingClassName)}
                  >
                    <h2 className="mb-16">{data.course_finance_title.text}</h2>
                    {data.course_finance_rows_with_icon.length > 0 && (
                      <ul className="space-y-10 mb-12 max-w-3xl">
                        {data.course_finance_rows_with_icon.map(
                          (item, itemIndex) => {
                            return (
                              <li key={itemIndex} className="flex">
                                <div className="w-12 flex-grow-0 flex-shrink-0">
                                  <Image
                                    fallbackAlt={data.course_finance_title.text}
                                    {...item.course_finance_row_icon}
                                  />
                                </div>
                                <div className="pl-6">
                                  <HtmlContent
                                    html={item.course_finance_row_body.html}
                                    className="text-lg c-prose"
                                  />
                                  {item.cta_label && item.cta_link.url && (
                                    <Button
                                      className="mt-4"
                                      color="inline"
                                      icon
                                      {...item.cta_link}
                                    >
                                      {item.cta_label}
                                    </Button>
                                  )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                    {data.course_finance_rows.length > 0 && (
                      <ul className="space-y-10 mb-12">
                        {data.course_finance_rows.map((item, itemIndex) => {
                          return (
                            <li
                              key={itemIndex}
                              className="shadow-lg py-10 px-18"
                            >
                              <HtmlContent
                                html={item.body.html}
                                className="text-lg c-prose"
                              />
                              {item.cta_label && item.cta_link.url && (
                                <Button
                                  className="mt-4"
                                  color="inline"
                                  icon
                                  {...item.cta_link}
                                >
                                  {item.cta_label}
                                </Button>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </Container>
                {data.course_testimonial_carousel?.document?.data
                  .testimonial_carousel_items && (
                  <TestimonialCarousel
                    items={
                      data.course_testimonial_carousel.document.data
                        .testimonial_carousel_items
                    }
                    preSwiperInnerClassName={leftSpacingClassName}
                    itemClassName="lg:max-w-none"
                  />
                )}
              </CourseViewSection>
              <CourseViewSection
                id={toSlug("FAQs")}
                className="pb-16 md:pb-32 pt-12 mb-4"
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className="mb-10">{data.course_faqs_title.text}</h2>
                    <Faqs
                      canAnimate
                      className="mb-12"
                      items={data.course_faqs_tabs.map((faq) => {
                        return {
                          header: faq.course_faqs_header.text,
                          body: (
                            <HtmlContent
                              html={faq.course_faqs_body.html}
                              className="c-prose"
                            />
                          ),
                        };
                      })}
                    />
                    <Button {...data.course_faqs_cta_link} color="inline" icon>
                      {data.course_faqs_cta_label.text}
                    </Button>
                  </div>
                </Container>
              </CourseViewSection>
            </>
          );
        }}
      </StickyLayout>
      {(data.curriculum_form_guid && !isLimitedCoursePage) ? (
        <Modal ref={modalElement}>
          <div className="max-w-sm space-y-8 px-4">
            <h6 className="h3">Get our {data.title.text} curriculum</h6>
            <div>
              <HubspotForm formId={data.curriculum_form_guid} />
            </div>
          </div>
        </Modal>
      ) : null}

      {(data.curriculum_form_guid && !isLimitedCoursePage) ? (
        <Modal ref={skillsEligibilityModalElement}>
          <div className="max-w-sm space-y-8 px-4">
            <h6 className="h3">Get our {data.title.text} curriculum</h6>
            <div>
              <HubspotForm formId={data.curriculum_form_guid} />
            </div>
          </div>
        </Modal>
      ) : null}

      {isLimitedCoursePage ? (
        <Container className="mb-12">
          <h6 className="h3 mb-6 mt-12" id="register-interest">
            Register your interest
          </h6>
          <HubspotForm formId="26aa8d8f-90c1-4f63-b0c4-6ac55a69aa64" />
        </Container>
      ) : null}

      {showApplyNowButton && (
        <div className="block md:hidden">
          <div className="fixed w-full bottom-0 bg-white p-4 z-aboveAll shadow-2xl">
            <Button
              color="primary"
              className="w-full"
              onClick={(e) => scrollTo(toSlug("Course Dates"))}
              icon
            >
              Apply Now
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

CourseView.propTypes = {
  data: PropTypes.object.isRequired,
  doesShowEligibility: PropTypes.bool,
  isPartTime: PropTypes.bool,
};

CourseView.defaultProps = {
  doesShowEligibility: false,
  isPartTime: false,
};

export default CourseView;
