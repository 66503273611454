import React from "react";

export default function CourseComparisonPrice({
    compareAgainstPrice,
    compareAgainstExtraInfo,
    compareToPrice,
    compareToExtraInfo
}) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 text-black">
      <div className="hidden md:block"></div>

      <div
        className="border-gray-darker border-b border-l border-r p-2 px-6 rounded-bl-md"
        align="left"
      >
        <div className="flex flex-col md:flex-row md:items-end gap-x-2">
          <h5>Price</h5>
          <h4 className="font-bold text-3xl">{compareAgainstPrice}</h4>
        </div>
        <p className="font-normal text-xs">{compareAgainstExtraInfo}</p>
      </div>
      <div
        className="border-gray-darker border-b border-r  p-2 px-6 rounded-br-md"
        align="left"
      >
        <div className="flex flex-col md:flex-row md:items-end gap-x-2">
          <h5>Price</h5>
          <h4 className="font-bold text-3xl">{compareToPrice}</h4>
        </div>
        <p className="font-normal text-xs">{compareToExtraInfo}</p>
      </div>
    </div>
  );
}
