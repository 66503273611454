import React from "react";

export default function CourseComparisonHeading({
  compareAgainst,
  compareTo
}) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3">
      <div className="hidden md:block"></div>
      <div className="border-gray-darker table-cell md:border-t border-l border-r  p-4 px-6 md:rounded-tl-md">
        <h3 className="text-left font-black hidden md:block">
          Northcoders
        </h3>
        <div className="pill bg-green-500 text-white h-full md:h-auto grid items-center font-medium text-sm md:inline-block px-4 text-center rounded-md uppercase ">
          {compareAgainst}
        </div>
      </div>
      <div className="border-gray-darker md:border-t border-r p-4 px-4 sm:px-6 md:rounded-tr-md">
        <h3 className="text-left font-black hidden md:block">
          Northcoders
        </h3>
        <div className="pill bg-purple-600 text-white h-full md:h-auto grid items-center font-medium text-sm md:inline-block px-2 sm:px-4 py-2 md:py-0 text-center rounded-md uppercase">
          {compareTo}
        </div>
      </div>
    </div>
  );
}
