import React from "react";

import Tick from "../../../assets/png/tick.png";
import Line from "../../../assets/png/line.png";

export default function CourseComparisonContentTable({ tableContent }) {
  return (
    <>
      {tableContent.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === tableContent.length - 1;
        const isOdd = index % 2 === 0;

        const TickIcon = () => (
          <img src={Tick} alt="Tick icon" className="mx-auto" />
        );
        const LineIcon = () => (
          <img src={Line} alt="Line icon" className="mx-auto" />
        );

        return (
          <div className="text-gray-darker-1" key={item.content_label.text}>
            <div
              className={`grid-cols-3 md:grid hidden
                ${isOdd ? "bg-gray-lighter" : ""}
                ${isFirst ? "rounded-tl-md" : ""}
                ${isLast ? "rounded-bl-md" : ""}
              `}
            >
              <div
                className={`
                  border-gray-darker text-right border-l p-2 w-full
                  ${isFirst ? "rounded-tl-md border-t" : ""}
                  ${isLast ? "border-b rounded-bl-md " : ""}
                `}
              >
                {item.content_label.text}
              </div>
              <div
                className={`
                    border-gray-darker border-l border-r text-center p-2
                    grid items-center
                    ${isLast ? "border-b" : ""}
                    ${isFirst ? "border-t" : ""}
                `}
              >
                {item.does_compare_against_have ? <TickIcon /> : <LineIcon />}
              </div>
              <div
                className={`
                    border-gray-darker text-center p-2 border-r
                    grid items-center
                    ${isFirst ? "border-t" : ""}
                    ${isLast ? "border-b" : ""}`}
              >
                {item.does_compare_to_have ? <TickIcon /> : <LineIcon />}
              </div>
            </div>

            <div className="block md:hidden">
              <div
                className={`border-gray-darker bg-gray-lighter text-center border-l border-r  p-2 w-full
                ${isLast ? "md:rounded-bl-md" : ""}}`}
              >
                {item.content_label.text}
              </div>
              <div className="grid grid-cols-2">
                <div
                  className={`border-gray-darker border-l border-r ${isLast ? "border-b" : ""
                    } text-center p-2 `}
                >
                  {item.does_compare_against_have ? <TickIcon /> : <LineIcon />}
                </div>
                <div
                  className={`border-gray-darker text-center p-2 border-r ${isLast ? "border-b" : ""
                    }`}
                >
                  {item.does_compare_to_have ? <TickIcon /> : <LineIcon />}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
