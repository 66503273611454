import React from 'react'
import PropTypes from 'prop-types'

// Generic
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

// Local
import CourseViewOverviewDetailsItem from './CourseViewOverviewDetailsItem'

const CourseViewOverviewDetails = (props) => {
  const { data } = props

  const {
    price_heading,
    start_date_bullets,
    duration_bullets,
    price_bullets,
    price_total
  } = data;

  

  // If the `price_heading` has not been filled in, then default the 
  // heading to 'Price'
  let priceHeading = 'Price';
  if (price_heading) {
    if (price_heading.text !== '') {
      priceHeading = price_heading.text;

      if (priceHeading === null) {
        priceHeading = 'Price';
      }
    }
  }

  // As we still have the older `course_price_total` in the CMS, we want to check
  // if our new field is empty, if so, then we want to show the old field
  // with a £ prefixed to it. This can be phased out once we're happy
  // with the new field being filled everywhere.
  let price = '£' + data.course_price_total;
  if (price_total) {
    if (price_total.text !== '') {
      price = price_total.text;
    }
  }
  
  // If there are no bullet points for anything provided, then we don't 
  // want to have the border within CourseViewOverviewDetailsItem.
  // We will pass this through and decide there.
  let hasBullets = false;
  if (start_date_bullets || duration_bullets || price_bullets) {
    if (start_date_bullets.text.length > 0 || duration_bullets.text.length > 0 || price_bullets.text.length > 0) {
      hasBullets = true;
    }
  }

  const priceBody = <HtmlContent className={'c-prose'} html={data.price_body.html} />;
  
  return (
    <div className='sm:grid grid-cols-3 justify-between space-y-4 sm:space-y-0 sm:space-x-4 shadow-lg p-4 md:px-10 md:py-8 mb-12'>
      <CourseViewOverviewDetailsItem
        title='Start date'
        header={data.course_start_date}
        newBody={start_date_bullets}
        body={data.course_location.text}
        hasBullets={hasBullets}
      />
      <CourseViewOverviewDetailsItem
        title='Duration'
        header={data.course_duration.text}
        newBody={duration_bullets}
        body={<><span className='font-base'>{data.course_duration_days.text}</span> {data.course_duration_times.text}</>}
        hasBullets={hasBullets}
      />
      <CourseViewOverviewDetailsItem
        title={priceHeading}
        header={price}
        newBody={price_bullets}
        body={priceBody}
        hasBullets={hasBullets}

        // todo -> remove this when certain we don't need
        // body={
        //   data.course_price_breakdown.map((breakdown, breakdownIndex) => {
        //     return (
        //       <div
        //         key={breakdownIndex}
        //         className='whitespace-no-wrap'
        //       >
        //         <span className='font-medium'>£{breakdown.course_price_breakdown_price}</span> {breakdown.course_price_breakdown_description.text}
        //         {breakdown.course_price_breakdown_extra_info.html && (
        //           <div className='inline relative ml-1 group'>
        //             <IconInfo className='inline-block w-4 fill-current text-primary align-text-bottom cursor-pointer' />
        //             <div className='hidden group-hover:block w-64 absolute bottom-1/1 right-0 xl:-right-16 bg-midnight p-6 mb-2'>
        //               <HtmlContent
        //                 html={breakdown.course_price_breakdown_extra_info.html}
        //                 className='text-white whitespace-normal c-prose'
        //               />
        //               <div className='block text-lg text-midnight absolute top-1/1 right-0 xl:right-16 z-10'>
        //                 <span className='block triangle-down' />
        //               </div>
        //             </div>
        //           </div>
        //         )}
        //       </div>
        //     )
        //   })
        // }
      />
    </div>
  )
}

CourseViewOverviewDetails.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseViewOverviewDetails
