import React from "react";

export default function CloseIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-1.5"
        x2="18.7064"
        y2="-1.5"
        transform="matrix(-0.706855 -0.707358 0.59744 -0.801913 16.0907 14.2321)"
        stroke="white"
        stroke-width="3"
      />
      <line
        y1="-1.5"
        x2="18.5986"
        y2="-1.5"
        transform="matrix(0.734377 -0.678742 0.566857 0.823816 2.48026 15.6758)"
        stroke="white"
        stroke-width="3"
      />
    </svg>
  );
}
